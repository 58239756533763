import React from "react"

const RaindropSVG: React.FC<{ index: number }> = ({ index }) => {
  return (
    <svg
      fill="currentColor"
      viewBox="0 0 300 300"
      xmlns="http://www.w3.org/2000/svg"
      className={`raindrop raindrop-${index}`}
    >
      <path d="M63.26 47.47c5.5 8.5 9.13 16.38 8.11 17.6-1.02 1.22-9.39-6.77-14.89-15.27-3.66-5.67-6.42-12.41-8.29-20.23 6.38 6.26 11.41 12.23 15.07 17.9z" />
      <path d="M126.9 45.88c.75 3.2-2.09.9-5.73-3.23-3.65-4.17-8.13-10.25-11.43-17.47-3.29-7.19-5.42-15.56-3.93-15.73 1.47-.19 6.58 7.86 11.3 16.21 4.7 8.33 9.04 17.02 9.79 20.22z" />
      <path d="M38.45 64.32c-3.07-4-3.91-1.67-1.9 5.23 2.01 6.9 6.85 18.32 11.74 26.89 4.88 8.57 9.8 14.29 10.45 12.58.67-1.67-2.95-10.8-7.42-20.55-4.47-9.76-9.8-20.15-12.87-24.15z" />
      <path d="M109.04 72.82c1.04 2.56-1.38 1.01-4.66-1.97-3.29-3.01-7.48-7.49-11-13.07-3.5-5.56-6.34-12.23-5.28-12.55 1.03-.34 5.99 5.7 10.7 12.04 4.7 6.33 9.19 13 10.24 15.55z" />
      <path d="M160.88 75.01c.75 3.2-2.09.91-5.73-3.23-3.65-4.17-8.13-10.24-11.43-17.47-3.29-7.19-5.42-15.56-3.93-15.72 1.47-.19 6.58 7.86 11.3 16.21 4.7 8.32 9.04 17.02 9.79 20.21z" />
      <path d="M58.58 154.95c1.04 2.56-1.38 1.01-4.65-1.97-3.3-3.01-7.49-7.49-11.01-13.07-3.49-5.56-6.34-12.23-5.28-12.55 1.04-.34 5.99 5.7 10.71 12.05 4.69 6.32 9.19 12.99 10.23 15.54z" />
      <path d="M77.1 106.91c-1.85-4.64.04-5.12 4.24-1.09 4.2 4.02 10.69 12.51 15.16 20.39 4.49 7.89 6.96 15.17 5.57 15.57-1.38.43-6.64-6.06-12.08-13.72-5.44-7.66-11.05-16.5-12.89-21.15z" />
      <path d="M152.55 103.2c2.12 3.88.7 4.3-3.18.96s-10.2-10.42-14.85-17c-4.65-6.59-7.64-12.68-6.6-13.03 1.02-.37 6.09 5.03 11.46 11.42 5.38 6.39 11.06 13.76 13.17 17.65z" />
      <path d="M171.07 99.15c6 9.27 10.03 17.78 9.01 19-1.02 1.22-9.98-7.69-15.98-16.97-4-6.18-7.09-13.44-9.29-21.78 6.84 6.98 12.27 13.56 16.26 19.75z" />
      <path d="M110.55 98.13c-2.54-1.98-.97 2.7 2.03 9.02 3.02 6.36 7.51 14.44 13.07 21.18 5.54 6.72 12.16 12.13 12.46 10.07.31-2.02-5.73-11.55-12.06-20.62-6.31-9.03-12.96-17.66-15.5-19.65z" />
      <path d="M204.21 110.5c3.46 4.71 4.19 2.19 1.67-5.65-2.52-7.83-8.27-20.93-13.89-30.86-5.62-9.94-11.11-16.71-11.69-14.87-.61 1.8 3.73 12.25 9 23.47 5.26 11.22 11.46 23.21 14.91 27.91z" />
      <path d="M101.57 203.79c3.45 4.71 4.19 2.19 1.67-5.65-2.52-7.83-8.27-20.93-13.9-30.86-5.62-9.94-11.11-16.71-11.69-14.87-.6 1.8 3.74 12.25 9 23.47 5.27 11.22 11.46 23.21 14.92 27.91z" />
      <path d="M75.08 199.5c6 9.27 10.03 17.78 9.01 19-1.01 1.22-9.98-7.69-15.98-16.97-3.99-6.18-7.09-13.44-9.29-21.78 6.85 6.98 12.27 13.56 16.26 19.75z" />
      <path d="M144.81 220.2c3.45 4.71 4.18 2.19 1.66-5.65-2.51-7.83-8.27-20.93-13.89-30.86-5.62-9.94-11.11-16.71-11.69-14.87-.6 1.8 3.73 12.25 9 23.47 5.27 11.22 11.46 23.21 14.92 27.91z" />
      <path d="M179.36 191.32c1.43 2.8-1.33 1.13-5.17-2.11-3.87-3.26-8.87-8.13-13.3-14.22-4.4-6.06-8.25-13.36-7.17-13.73 1.05-.38 7.06 6.19 12.85 13.1 5.77 6.89 11.37 14.16 12.79 16.96z" />
      <path d="M206.82 150.84c5.49 8.51 9.12 16.39 8.1 17.6-1.02 1.22-9.39-6.76-14.88-15.27-3.67-5.67-6.43-12.41-8.29-20.23 6.38 6.27 11.4 12.24 15.07 17.9z" />
      <path d="M182.43 149.57c6 9.27 10.03 17.78 9.01 19-1.02 1.22-9.98-7.69-15.98-16.97-4-6.18-7.09-13.44-9.29-21.78 6.84 6.98 12.26 13.56 16.26 19.75z" />
      <path d="M187.57 240.44c2.12 3.89.7 4.31-3.18.96-3.88-3.34-10.2-10.41-14.85-17-4.65-6.58-7.64-12.68-6.6-13.03 1.02-.37 6.09 5.03 11.46 11.42 5.38 6.39 11.05 13.77 13.17 17.65z" />
      <path d="M253.23 213.3c1.43 2.8-1.33 1.13-5.17-2.1-3.87-3.26-8.87-8.14-13.3-14.23-4.4-6.06-8.25-13.36-7.17-13.72 1.05-.39 7.06 6.18 12.85 13.09 5.77 6.89 11.37 14.17 12.79 16.96z" />
      <path d="M137.87 256.25c1.05 2.55-1.37 1-4.65-1.98-3.29-3.01-7.48-7.49-11-13.07-3.5-5.55-6.34-12.23-5.28-12.55 1.03-.33 5.99 5.71 10.7 12.05 4.7 6.32 9.19 12.99 10.23 15.55z" />
      <path d="M110.83 251.19c5.49 8.51 9.12 16.39 8.1 17.6-1.02 1.22-9.39-6.76-14.88-15.27-3.66-5.67-6.43-12.41-8.29-20.23 6.38 6.27 11.41 12.24 15.07 17.9z" />
      <path d="M193.52 196.57c-1.85-4.64.04-5.12 4.24-1.1s10.69 12.51 15.17 20.4c4.48 7.88 6.95 15.16 5.56 15.56-1.38.43-6.64-6.06-12.08-13.72-5.43-7.66-11.05-16.5-12.89-21.14z" />
      <path d="M208.9 240.02c-3.07-4.01-3.91-1.67-1.9 5.23 2.01 6.89 6.85 18.31 11.74 26.88 4.88 8.57 9.8 14.29 10.45 12.59.67-1.67-2.95-10.8-7.42-20.56-4.47-9.76-9.8-20.14-12.87-24.14z" />
      <path d="M225.84 208.07c-2.54-1.99-.97 2.69 2.02 9.01 3.03 6.36 7.52 14.44 13.08 21.18 5.53 6.72 12.16 12.13 12.45 10.07.32-2.02-5.72-11.55-12.05-20.62-6.31-9.03-12.97-17.66-15.5-19.64z" />
      <path d="M173.32 271.44c6 9.27 10.03 17.78 9.01 19-1.02 1.22-9.99-7.69-15.98-16.97-4-6.18-7.09-13.44-9.29-21.78 6.84 6.98 12.26 13.56 16.26 19.75z" />
    </svg>
  )
}

export default RaindropSVG
