import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Layout from "../components/Layout"
import GatsbyImage from "gatsby-image"
import {
  ABOUT_US_ROUTE,
  CUSTOM_ORDER_REQUEST_ROUTE,
  HOME_ROUTE,
  SEASONAL_ROUTE,
  WEDDING_ROUTE,
} from "../constants"
import { Product } from "../types"
import LandingTopSVG from "../components/LandingTopSVG"
import LandingBottomSVG from "../components/LandingBottomSVG"
import Raindrop from "../components/Raindrop"
import LandingTopSmallSVG from "../components/LandingTopSmallSVG"
import LandingBottomSmallSVG from "../components/LandingBottomSmallSVG"
import WaveSVG from "../components/WaveSVG"
import { convertCentsToCurrency } from "../utils"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      landing1: file(relativePath: { eq: "landing_1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      landing2: file(relativePath: { eq: "landing_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      landing3: file(relativePath: { eq: "landing_3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pageContent {
        enableSeasonalProducts
        landingSeasonalHeader
        landingSeasonalTopMessage
        landingSeasonalBottomMessage
        seasonalPageColor
        seasonalPageTitle
      }
      allProduct(filter: { featured: { eq: true } }) {
        nodes {
          productImage {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          id
          name
          path
          category
          minPrice
        }
      }
    }
  `)
  const weddingProducts = []
  const homeProducts = []
  const seasonalProducts = []
  data.allProduct.nodes.forEach((product: Product) => {
    if (
      product.category === "Wedding" &&
      weddingProducts.length < 3 &&
      product.id !== "XXGIEDSEVJtEl3fBrPkz"
    ) {
      // Skipping this ID since the same image appears on the landing page below
      weddingProducts.push(product)
    } else if (product.category === "Home" && homeProducts.length < 3) {
      homeProducts.push(product)
    } else if (product.category === "Seasonal") {
      seasonalProducts.push(product)
    }
  })
  const featuredProducts = [...weddingProducts, ...homeProducts]
  return (
    <Layout
      pageTitle="Custom Acrylics for Weddings and Home Decor"
      pathname="/"
      landingPage
    >
      <>
        <div className="landing-container">
          <Raindrop index={1} />
          <Raindrop index={2} />
          <LandingTopSVG />
          <LandingTopSmallSVG />
          <div className="landing-content-container">
            <div className="landing-content">
              <h1>
                HANDMADE
                <br />
                CUSTOM
                <br />
                CREATIONS
              </h1>
              <p className="font-size-18">
                Shop Dzign Studio for high-quality handmade acrylics, industrial
                frames and custom woodwork.
              </p>
              <div className="landing-button-grid">
                <div className="button-grid-item">
                  <Link
                    to={WEDDING_ROUTE}
                    className="btn rounded-btn pink-btn full-width"
                  >
                    SHOP FOR WEDDINGS
                  </Link>
                </div>
                <div className="button-grid-item">
                  <Link
                    to={HOME_ROUTE}
                    className="btn rounded-btn white-btn full-width"
                  >
                    SHOP FOR HOME
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <LandingBottomSVG />
          <LandingBottomSmallSVG />
        </div>
        <section className="landing-section top-section">
          <div className="landing-svg-container">
            <WaveSVG />
          </div>
          <div className="layout-container">
            <div className="text-center landing-body-content wide">
              <h2 className="margin-bottom-4">
                PERSONALIZED DECOR MADE JUST FOR YOU
              </h2>
              <p className="margin-0 text-muted font-size-18">
                Shop our featured customizable creations
              </p>
            </div>
            <div className="grid-container product-grid flex-center">
              {featuredProducts.map((product, index) => {
                return (
                  <div className="featured-item" key={`featured-${index}`}>
                    <Link className="product-link" to={product.path}>
                      <div className="product-container">
                        <GatsbyImage
                          fluid={product.productImage.childImageSharp.fluid}
                        />
                        <div className="price-container">
                          <span>
                            ${convertCentsToCurrency(product.minPrice)}
                          </span>
                        </div>
                      </div>
                      <p className="text-truncate">{product.name}</p>
                    </Link>
                  </div>
                )
              })}
            </div>
            <div className="text-center landing-body-content">
              <p className="font-size-18">
                We take pride in everything we make. If you want something that
                isn't displayed on our site, please feel free to let us know
                about it. We take{" "}
                <Link to={CUSTOM_ORDER_REQUEST_ROUTE} className="default">
                  custom requests
                </Link>
                , too!
              </p>
              <div>
                <Link
                  to={HOME_ROUTE}
                  className="btn rounded-btn light-orange-btn full-width-mobile"
                >
                  SHOP DZIGN STUDIO TODAY
                </Link>
              </div>
            </div>
          </div>
          {data.pageContent.enableSeasonalProducts && (
            <div className="landing-svg-container white-svg-container">
              <WaveSVG />
            </div>
          )}
        </section>
        {data.pageContent.enableSeasonalProducts && (
          <section
            className="landing-section landing-wave-padding"
            style={{ backgroundColor: data.pageContent.seasonalPageColor }}
          >
            <div className="layout-container">
              <div className="text-center landing-body-content wide">
                <h2 className="margin-bottom-4">
                  {data.pageContent.landingSeasonalHeader.toUpperCase()}
                </h2>
                <p className="margin-0 font-size-18">
                  {data.pageContent.landingSeasonalTopMessage}
                </p>
              </div>
              <div className="grid-container product-grid flex-center">
                {seasonalProducts.map((product, index) => {
                  return (
                    <div
                      className="featured-item seasonal-item"
                      key={`featured-${index}`}
                    >
                      <Link className="product-link" to={product.path}>
                        <div className="product-container">
                          <GatsbyImage
                            fluid={product.productImage.childImageSharp.fluid}
                          />
                          <div className="price-container">
                            <span>
                              ${convertCentsToCurrency(product.minPrice)}
                            </span>
                          </div>
                        </div>
                        <p className="text-truncate">{product.name}</p>
                      </Link>
                    </div>
                  )
                })}
              </div>
              <div className="text-center landing-body-content">
                <p className="font-size-18">
                  {data.pageContent.landingSeasonalBottomMessage}
                </p>
                <div>
                  <Link
                    to={SEASONAL_ROUTE}
                    className="btn rounded-btn white-btn full-width-mobile"
                  >
                    SHOP {data.pageContent.seasonalPageTitle.toUpperCase()}
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="landing-svg-container white-svg-container"
              style={{ color: data.pageContent.seasonalPageColor }}
            >
              <WaveSVG />
            </div>
          </section>
        )}
        <section
          className={`landing-section${
            data.pageContent.enableSeasonalProducts
              ? " landing-wave-padding"
              : ""
          }`}
        >
          <div className="layout-container landing-grid-container">
            <div className="grid-container add-width-2">
              <div className="grid-item-2 padding-2 landing-desktop-img">
                <div className="landing-img-container">
                  <GatsbyImage fluid={data.landing1.childImageSharp.fluid} />
                </div>
              </div>
              <div className="grid-item-2 padding-2">
                <div className="landing-body-content">
                  <h2>CUSTOM CREATIONS FOR YOUR WEDDING</h2>
                  <div className="landing-img-container landing-mobile-img">
                    <GatsbyImage fluid={data.landing1.childImageSharp.fluid} />
                  </div>
                  <p className="font-size-18">
                    Dzign Studio helps to make your special day even more
                    special. We've created dozens of tasteful decorations to fit
                    all sorts of wedding venues. Our frames, acrylics and
                    woodwork can all be customized to perfectly suit your
                    wedding theme!
                  </p>
                  <div>
                    <Link
                      to={WEDDING_ROUTE}
                      className="btn rounded-btn pink-btn full-width-mobile"
                    >
                      SHOP FOR WEDDING DECOR
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="landing-section">
          <div className="layout-container landing-grid-container">
            <div className="grid-container add-width-2 row-reverse">
              <div className="grid-item-2 padding-2 landing-desktop-img">
                <div className="landing-img-container">
                  <GatsbyImage fluid={data.landing2.childImageSharp.fluid} />
                </div>
              </div>
              <div className="grid-item-2 padding-2">
                <div className="landing-body-content">
                  <h2>THE PERFECT PIECE TO FILL YOUR HOME</h2>
                  <div className="landing-img-container landing-mobile-img">
                    <GatsbyImage fluid={data.landing2.childImageSharp.fluid} />
                  </div>
                  <p className="font-size-18">
                    Unique designs and decorations make a house feel like a
                    home. Dzign Studio can build you the perfect piece of custom
                    decor to fill out any room, nook or cranny.
                  </p>
                  <div>
                    <Link
                      to={HOME_ROUTE}
                      className="btn rounded-btn light-orange-btn full-width-mobile"
                    >
                      SHOP FOR HOME DECOR
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="landing-svg-container white-svg-container">
            <WaveSVG />
          </div>
        </section>
        <section className="landing-section landing-bg-pink landing-wave-padding">
          <div className="layout-container landing-grid-container">
            <div className="grid-container add-width-2">
              <div className="grid-item-2 padding-2 landing-desktop-img">
                <div className="landing-img-container">
                  <GatsbyImage fluid={data.landing3.childImageSharp.fluid} />
                </div>
              </div>
              <div className="grid-item-2 padding-2">
                <div className="landing-body-content">
                  <h2>MADE WITH CARE AND SHIPPED WITH LOVE</h2>
                  <div className="landing-img-container landing-mobile-img">
                    <GatsbyImage fluid={data.landing3.childImageSharp.fluid} />
                  </div>
                  <p className="font-size-18">
                    Dzign Studio was started by Sam and Jared Dziak, a couple
                    with a passion for creativity and collaboration. We love
                    designing and building out custom concepts to make our
                    customers happy, and we do it all out of our own studio in
                    Pittsburgh, Pennsylvania.
                  </p>
                  <div>
                    <Link
                      to={ABOUT_US_ROUTE}
                      className="btn rounded-btn white-btn full-width-mobile"
                    >
                      LEARN MORE ABOUT US
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  )
}
