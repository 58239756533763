import React from "react"

export default () => {
  return (
    <svg
      className="landing-img bottom-img big-img"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1280 550"
      width="100%"
    >
      <path
        fill="#ffe0b2"
        d="M113.3 489.62C149.08 452.62 156.63 384.71 141.07 337.7C125.25 290.51 86.62 264.91 70.28 209.64C53.96 154.87 59.7 70.74 29.82 41.51C-0.55 12.4 -66.56 37.68 -108.94 80.87C-151.08 123.75 -169.58 184.54 -173.98 231.72C-178.88 279 -169.69 312.67 -169.87 355.76C-170.08 398.34 -179.7 449.83 -161.4 481.58C-143.32 513.63 -97.58 525.74 -42.44 528.94C12.7 532.14 77.25 526.43 113.3 489.62Z"
      />
      <path
        fill="#ffcdd2"
        d="M594.17 356.36C639.12 361.26 665.62 397.08 701.21 422.72C736.5 448.27 750.76 480.58 737.14 501.89C723.85 523.16 682.94 533.65 638.6 540.47C594.52 547.51 546.69 550.93 496.67 540.33C446.35 529.65 385.39 551.76 342.9 521.38C300.38 491.13 297.02 435.21 321.52 405.29C346.03 375.38 379.73 373.84 431.3 371.71C482.57 369.5 549.22 351.45 594.17 356.36Z"
      />
      <path
        fill="#b05134"
        d="M1166.14 450.24C1129.7 478.63 1096.93 508.28 1064.88 512.38C1032.96 516.75 1001.54 496.03 946.87 491.27C892.55 486.34 814.5 497.27 773.95 473.92C733.53 450.84 730.35 392.92 772.5 357.4C814.29 322.04 901.26 308.81 954.5 285.37C1007.6 261.67 1026.84 227.5 1060.52 205.14C1093.85 182.95 1141.27 172.75 1179.65 181.7C1217.67 190.83 1246.65 219.12 1262.41 253.47C1278.04 287.55 1280.6 327.96 1261.81 361.25C1243.15 394.8 1202.57 421.85 1166.14 450.24Z"
      />
      <path
        fill="#255a4d"
        d="M232.2 537.57C244.14 539.24 264.18 542.04 277.51 543.91C286.21 545.13 294.89 541.64 299.49 535.07C301 532.93 304.76 527.56 310.77 518.97C321.76 506.65 328.62 498.95 331.36 495.87C334.82 491.99 331.52 486.35 325.89 486.52C314.81 486.86 287.1 487.7 242.77 489.05C262.41 476.92 274.68 469.34 279.59 466.31C279.59 466.31 320.65 441.76 320.65 441.76C328.78 437.06 341.52 429.69 351.92 423.67C357.33 420.54 359.36 414.46 356.72 409.29C353 401.96 346.93 390.04 343.34 382.99C340.66 377.72 333.05 376.01 327.8 379.5C321.65 383.57 315.2 387.85 309.47 391.65C309.47 391.65 284.19 407.47 284.19 407.47C277.8 411.24 261.82 420.68 236.25 435.77C261.29 408.07 280.22 385.83 293.02 369.04C305.83 352.25 312.51 340.93 313.08 335.06C302.71 326.26 296.23 320.77 293.64 318.57C291.02 316.34 286.75 316.61 284.53 319.15C279.38 325 266.52 339.64 245.95 363.06C219.47 390.37 202.93 407.45 196.31 414.27C195.27 415.35 193.3 414.37 193.82 413.04C201.93 392.36 222.84 339.09 230.72 319C232.41 314.7 228.32 310.36 223.17 310.96C219.14 311.43 215.17 311.9 210.36 312.46C205.79 313 201.87 315.56 200 319.25C193.36 332.33 180.02 358.62 174.27 369.95C174.27 369.95 157.2 398.37 157.2 398.37C152.61 404.92 143.63 417.73 137.83 426.01C136.95 427.28 134.7 426.63 134.86 425.15C136.71 406.71 141.47 359.4 143.27 341.47C143.62 338.09 139.29 335.89 136.09 337.83C130.75 341.06 124.89 344.6 120.08 347.5C113.3 351.6 109.02 358.16 108.45 365.33C106.58 388.53 101.82 447.52 99.94 470.91C99.42 477.35 102.82 483.56 108.89 487.26C117.11 492.28 123.42 496.12 129.5 499.82C143.16 508.13 157.77 515.15 173.12 520.77C180.67 523.53 182.76 524.3 190.95 527.29C204.18 532.14 218.03 535.59 232.2 537.57Z"
      />
      <path
        fill="#ff9e80"
        d="M832.28 238.88C815.27 257.71 769.04 281.7 747.85 284.65C726.74 287.79 706.34 269.2 708.84 242.77C711.54 216.26 737.12 166.54 729.78 138.43C722.44 110.32 651.82 87.11 649.18 56.3C646.54 25.48 674.56 12.25 720.9 8.42C767.24 4.58 845.28 42.57 890.53 96.01C935.78 149.44 849.22 219.86 832.28 238.88Z"
      />
    </svg>
  )
}
