import React from "react"

export default () => {
  return (
    <svg
      className="landing-img top-img small-img"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 600 250"
      width="100%"
    >
      <path
        fill="#b05134"
        d="M35.86 140.7C-4.87 129.89 -2.13 129.49 -28.34 92.82C-54.83 56.32 -20.34 -21.39 -25.91 -67.21C-31.47 -113.02 87.03 -214.54 119.09 -231.01C151.41 -247.43 284.61 44.94 244.39 111.68C204.16 178.43 76.83 151.79 35.86 140.7Z"
      />
      <path
        fill="#ffe0b2"
        d="M520.72 -21.08C556.14 -30.97 611 -22.03 639.36 -21.08C667.97 -20.23 689.83 -22.4 724.96 -25.09C760.22 -28.05 808.84 -31.35 839.28 -16.08C869.83 -0.64 882.53 33.44 875.54 67.88C868.9 102.37 842.79 137.12 807.29 165.08C771.78 193.05 703.01 251.96 665.75 247.44C628.5 242.91 600.66 242.77 558.38 229.31C515.97 216.12 483.23 169.77 456.7 152.55C429.93 135.44 431.47 79.63 450.34 48.73C469.21 17.83 485.41 -11.01 520.72 -21.08Z"
      />
      <path
        fill="#ffcdd2"
        d="M457.02 12.9C431.64 56.88 366.58 65.24 308.08 56.04C249.38 47.23 250.48 2.06 316.57 -63.79C382.66 -129.64 481.8 -30.88 457.02 12.9Z"
      />
      <path
        fill="#255a4d"
        d="M428.62 1.96C421.73 3.91 410.17 7.17 402.48 9.35C397.46 10.76 393.71 15.02 392.98 20.11C392.74 21.77 392.14 25.94 391.18 32.61C388.54 42.75 386.89 49.09 386.23 51.62C385.4 54.82 388.68 57.4 391.67 55.91C397.56 52.96 412.29 45.6 435.85 33.82C428.48 45.99 423.88 53.6 422.04 56.64C422.04 56.64 406.43 81.59 406.43 81.59C403.3 86.44 398.39 94.03 394.38 100.23C392.29 103.45 392.82 107.62 395.62 110.09C399.58 113.58 406.03 119.27 409.85 122.63C412.7 125.15 417.26 124.29 419.17 120.89C421.4 116.92 423.74 112.74 425.82 109.04C425.82 109.04 435.24 93.24 435.24 93.24C437.69 89.39 443.79 79.75 453.57 64.33C447.44 87.22 443.15 105.31 440.72 118.59C438.28 131.88 437.69 140.36 438.95 144.04C446.89 146.78 451.85 148.49 453.84 149.17C455.85 149.86 458.07 148.65 458.6 146.57C459.82 141.77 462.85 129.76 467.71 110.55C474.72 87.54 479.1 73.16 480.85 67.41C481.12 66.5 482.45 66.6 482.52 67.53C483.66 82 486.57 119.29 487.67 133.35C487.91 136.35 491.27 137.96 493.89 136.33C495.94 135.04 497.96 133.78 500.4 132.25C502.73 130.8 504.16 128.28 504.18 125.6C504.28 116.07 504.47 96.92 504.56 88.67C504.56 88.67 506.19 67.32 506.19 67.32C506.92 62.23 508.35 52.29 509.28 45.87C509.41 44.88 510.8 44.72 511.11 45.65C515.02 57.22 525.06 86.92 528.87 98.17C529.59 100.29 532.51 100.55 533.72 98.59C535.74 95.32 537.96 91.74 539.78 88.8C542.35 84.65 542.91 79.64 541.3 75.17C536.13 60.73 522.98 23.99 517.76 9.43C516.32 5.41 512.83 2.51 508.57 1.78C502.79 0.8 498.37 0.04 494.1 -0.69C484.52 -2.32 474.76 -2.94 464.98 -2.53C460.17 -2.33 458.83 -2.27 453.62 -2.05C445.19 -1.7 436.8 -0.35 428.62 1.96Z"
      />
    </svg>
  )
}
