import React from "react"

export default () => {
  return (
    <svg
      className="landing-img bottom-img small-img"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 600 430"
      width="100%"
    >
      <path
        fill="#ffe0b2"
        d="M127.98 382.46C154.89 348.6 157.24 291.88 142.06 254.57C126.65 217.11 93.98 199.61 77.7 155.55C61.47 111.9 61.46 41.98 35.68 20.66C9.5 -0.51 -42.52 26.51 -74.41 66.09C-106.14 105.4 -117.75 157.27 -118.71 196.59C-120.06 236.06 -110.76 262.97 -108.52 298.52C-106.34 333.66 -111.28 377.04 -94.71 401.51C-78.31 426.26 -40.64 431.97 4.15 429.46C48.94 426.94 100.85 416.2 127.98 382.46Z"
      />
      <path
        fill="#ffcdd2"
        d="M453.48 407.8C489.97 396.98 553.82 396 570.27 374.7C586.87 353.65 600.33 328.39 619.58 285.41C638.55 242.6 635.47 175.23 634.89 132.16C634.46 89.35 592.38 54.32 548.17 65.32C504.23 76.16 458.02 132.78 418.24 158.78C378.31 184.54 344.68 179.42 313.86 191.62C283.33 203.66 255.89 232.85 246.59 266.18C237.56 299.35 246.67 336.67 265.84 367.68C284.85 398.44 314.08 423.13 346.86 428.07C379.8 433.26 417 418.62 453.48 407.8Z"
      />
      <path
        fill="#255a4d"
        d="M179.84 416.48C189.3 416.73 205.17 417.16 215.72 417.44C222.61 417.62 228.97 413.77 231.82 407.68C232.76 405.7 235.09 400.72 238.82 392.75C246.01 381.1 250.49 373.81 252.29 370.9C254.55 367.23 251.37 362.72 247.02 363.42C238.45 364.81 217.03 368.27 182.75 373.82C196.67 361.47 205.37 353.75 208.85 350.67C208.85 350.67 238.06 325.54 238.06 325.54C243.86 320.71 252.94 313.13 260.36 306.93C264.22 303.71 265.13 298.3 262.52 294.12C258.83 288.2 252.81 278.58 249.26 272.88C246.6 268.62 240.5 267.92 236.8 271.42C232.48 275.53 227.93 279.84 223.9 283.66C223.9 283.66 206 299.74 206 299.74C201.44 303.61 190.07 313.28 171.86 328.76C188.28 302.52 200.55 281.56 208.66 265.9C216.77 250.23 220.72 239.85 220.51 234.76C211.5 228.24 205.87 224.17 203.62 222.54C201.34 220.89 198.06 221.54 196.61 223.94C193.25 229.47 184.86 243.3 171.45 265.43C153.87 291.47 142.89 307.76 138.5 314.27C137.81 315.29 136.17 314.65 136.42 313.45C140.47 294.91 150.88 247.15 154.81 229.14C155.65 225.28 152 221.96 148.06 222.98C144.98 223.79 141.95 224.58 138.28 225.54C134.79 226.45 132.02 229.04 130.97 232.38C127.25 244.26 119.76 268.14 116.53 278.42C116.53 278.42 106.39 304.5 106.39 304.5C103.54 310.57 97.97 322.45 94.37 330.12C93.82 331.29 92.01 330.96 91.96 329.68C91.39 313.68 89.91 272.62 89.35 257.06C89.25 254.13 85.65 252.67 83.38 254.65C79.58 257.95 75.42 261.56 72 264.53C67.18 268.72 64.58 274.77 64.92 280.98C66 301.06 68.75 352.14 69.85 372.39C70.15 377.96 73.47 382.96 78.58 385.53C85.52 389.02 90.84 391.69 95.97 394.26C107.48 400.04 119.6 404.62 132.13 407.92C138.3 409.54 140 409.99 146.68 411.75C157.49 414.6 168.62 416.19 179.84 416.48Z"
      />
    </svg>
  )
}
