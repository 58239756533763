import React from "react"

export default () => {
  return (
    <svg
      className="landing-img top-img big-img"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1280 450"
      width="100%"
    >
      <path
        fill="#b05134"
        d="M441.2 66.07C422.49 104.07 360.54 128.3 293.84 140.48C227.45 153.01 155.91 153.36 71.8 140.24C-12.31 127.11 -109.28 100.15 -140.5 59.91C-171.62 19.43 -116.99 -31.05 -92.24 -86.8C-67.5 -142.56 -72.64 -203.59 -18.13 -218.98C36.39 -234.37 130.45 -207.13 209.04 -176.38C287.22 -145.74 349.71 -111.09 393.12 -67.64C436.52 -24.19 460.43 27.94 441.2 66.07Z"
      />
      <path
        fill="#ffe0b2"
        d="M568.25 -76.87C602.18 -84.21 635.35 -77.68 662.37 -74.73C689.62 -71.86 710.5 -72.48 744.03 -72.66C777.69 -73.11 824.1 -72.95 852.87 -55.61C881.74 -38.11 893.29 -3.34 886.06 30.39C879.16 64.21 853.71 96.9 819.4 122.18C785.1 147.46 742.07 165.08 706.63 157.95C671.19 150.81 643.24 118.75 603.16 102.38C562.94 86.27 510.7 86.03 485.69 67.04C460.45 48.13 462.53 10.66 481.03 -18.72C499.52 -48.09 534.43 -69.37 568.25 -76.87Z"
      />
      <path
        fill="#ffcdd2"
        d="M1559.83 91.62C1551.74 127.4 1487.75 154.83 1455.35 211.28C1422.95 267.73 1421.49 353.29 1386.88 393.93C1351.61 434.67 1282.53 430.57 1223.84 409.51C1164.87 388.83 1115.91 350.9 1086.71 305.4C1057.79 259.52 1047.97 206.17 1016.16 156.34C984.24 105.86 930.04 59.28 905.96 -2.42C881.6 -63.74 886.99 -140.47 942.29 -153.19C997.22 -166.2 1101.99 -115.86 1167.67 -107.24C1233.35 -98.62 1259.85 -132.37 1284.01 -132.52C1308.55 -132.38 1330.26 -99.57 1361.88 -77.61C1393.12 -55.93 1433.6 -44.99 1477.42 -17.41C1520.96 10.56 1568.21 55.45 1559.83 91.62Z"
      />
      <path
        fill="#255a4d"
        d="M836.86 22.96C825.64 25.52 806.82 29.83 794.3 32.7C786.13 34.57 779.73 41.05 778.11 49.11C777.58 51.74 776.25 58.33 774.13 68.88C769 84.83 765.8 94.8 764.52 98.79C762.91 103.81 767.94 108.18 772.87 106.03C782.57 101.8 806.83 91.21 845.64 74.27C832.77 93.09 824.72 104.85 821.5 109.55C821.5 109.55 794.27 148.09 794.27 148.09C788.82 155.57 780.28 167.28 773.31 176.85C769.68 181.82 770.16 188.5 774.43 192.65C780.48 198.53 790.32 208.08 796.14 213.74C800.49 217.97 807.88 216.96 811.24 211.69C815.16 205.53 819.29 199.06 822.94 193.32C822.94 193.32 839.44 168.89 839.44 168.89C843.7 162.93 854.34 148.05 871.37 124.24C859.53 160.23 851.07 188.72 845.99 209.69C840.92 230.67 839.23 244.14 840.93 250.1C853.41 255.07 861.21 258.18 864.34 259.42C867.5 260.68 871.18 258.91 872.2 255.65C874.57 248.09 880.5 229.2 889.98 198.98C903.24 162.86 911.53 140.29 914.84 131.26C915.36 129.84 917.48 130.1 917.51 131.59C918.06 154.73 919.45 214.35 919.98 236.83C920.09 241.64 925.34 244.47 929.69 242.06C933.09 240.18 936.44 238.32 940.49 236.08C944.34 233.94 946.86 230.04 947.14 225.77C948.13 210.6 950.12 180.11 950.98 166.98C950.98 166.98 955.48 133.08 955.48 133.08C957.1 125.04 960.27 109.31 962.31 99.15C962.62 97.6 964.86 97.44 965.28 98.95C970.53 117.68 984.01 165.77 989.13 183.99C990.09 187.43 994.75 188.06 996.86 185.04C1000.4 179.98 1004.27 174.45 1007.45 169.9C1011.92 163.5 1013.26 155.56 1011.09 148.32C1004.06 124.9 986.2 65.36 979.12 41.76C977.17 35.26 971.83 30.36 965.06 28.87C955.89 26.85 948.85 25.31 942.08 23.82C926.85 20.47 911.26 18.73 895.54 18.62C887.81 18.58 885.67 18.56 877.29 18.51C863.74 18.42 850.17 19.91 836.86 22.96Z"
      />
    </svg>
  )
}
